// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    showDebugInformation: true,
    sentryDNS: 'https://8f3b222b3c9d486ba804e51139f5f758@crash.siemens.com/211',
    name: "Stage",
    version: '42b5d469218454eaee0fc81351dc3a678a8c6b87',
    labomApi:'https://api-test.labom.com/dmprog',
    auth: {
        domain:"siemens-qa-00131.eu.auth0.com",
        clientId:"Uo989Mw3C5Veuuhu0yvleKRkkTgdGaKM",
        authorizationParams: {
          audience:"https://siemens-qa-00131.eu.auth0.com/api/v2/",
          redirect_uri: window.location.origin + '/',
          connection: 'main-tenant-oidc',
          scope: 'openid profile email https://stage.rsct.siemens.com/backend'
        },
        error:'/error',
      },
      httpInterceptor: {
        allowedList: ['/*'],
      },
};

// For easier debugging in development mode, you can import the following file
// to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//
// This import should be commented out in production mode because it will have a negative impact
// on performance if an error is thrown.
//
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
